.mainContainer {
  max-width: 1024px;
  margin:0 auto;
}

.mainMenuContainer {
  background: linear-gradient(to bottom, #ffc877 0%, #ffb43f 52%, #fc9b00 100%);
  list-style: none;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.05em;
  border-radius: 10px;
  border: 2px solid #8E5407;
  height: 40px;
  margin: 0;
}

.subMenuContainer {
  list-style: none;
  display: flex;
  max-width: 210px;
  position: absolute;
  flex: 1;
  flex-wrap: wrap;
  padding: 0;
  margin-left: -50px;
  height: 200px;
  background: white;
  border-style: solid;
  border-color: #8E5407;
  border-top: 0px;
  border-width: 2px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fc9b01;
  overflow: hidden;
  transition: all .5s;
}

.hidden {
  height: 0;
  transition: all .5s;
}

.link {
    text-decoration: none;
    color: black;
    padding: 0 10px;
    height: 40px;
    color: white;
    font-weight: bold;
    font-family: sans-serif;
    display: table-cell;
    vertical-align: middle;
    background: none;
    border: 2px solid transparent;
    border-bottom: 0;
    border-top: 0;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.activeLink {
  color: #f08103;
  transition: all 1s;
}

.tabletMenu {
  display: none;
}

.arrow {
  border: solid #ebe7e7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: 0.5s all;
  position: relative;
  top: -1px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: 0.5s all;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: 0.5s all;
}

@media only screen and (max-width: 425px) { /*Mobile to Tablet */

}

@media only screen and (max-width: 768px) { /* Tablet to Laptop*/
  .mainMenuContainer {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    position: fixed;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    flex-direction: column;
    justify-content: normal;
    transition: 0.5s left;
  }

  .tabletMenuClosed {
    left: -100%;
    transition: 0.5s left;
  }

  .subMenuContainer {
    position: relative;
    margin: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .link {
    width: 100%;
    display: block;
    line-height: 41px;
  }

  .tabletMenu {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 22px;
    left: 11px;
  }

  .lockedHeight {

  }

  .tabletMenuArrow {
    width: 30px;
  }
}
