.headerLogo {
  width: auto;
  max-width: 100%;
  position: relative;
}

@media only screen and (max-width: 425px) { /*Mobile to Tablet */

}

@media only screen and (max-width: 768px) { /* Tablet to Laptop*/
  .headerLogo {
    max-width: 90%;
    left: 10%;
  }
}
