.page {
    background: #FAD9A6;
    padding: 20px;
    width: 90%;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0px;
    border-right: 1px solid #8E5407;
    border-left: 1px solid #8E5407;
    border-bottom: 1px solid #8E5407;
    line-height: 1.5em;
    transition: 1s height;
}

.aligncenter {
    display: block;
    margin: 0 auto;
}

.loader {
  display: block;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 425px) { /*Mobile to Tablet */

}

@media only screen and (max-width: 768px) { /* Tablet to Laptop*/
  .page {
    border: 0;
    border-radius: 0;
  }
}
